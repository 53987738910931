import MainLayout from '@/components/main-layout';
import { graphql } from 'gatsby';
import React from 'react';
import ProjectsGallery from '@/components/projects/projects-gallery';
import SEO from '@/components/seo';

type RodinneABytoveStavbyProps = {
  data: any;
};

const RodinneABytoveStavby: React.FC<RodinneABytoveStavbyProps> = ({
  data,
}) => {
  const {
    allContentfulProjects: { nodes: projects },
  } = data;
  return (
    <>
      <SEO
        title="Projekty - Rodinné a bytové stavby"
        description="Pozrite si ukážku našich projektov z kategórie Rodinných a bytových stavieb."
        image="/house.jpg"
      />
      <MainLayout>
        <ProjectsGallery projects={projects} />
      </MainLayout>
    </>
  );
};

export const query = graphql`
  {
    allContentfulProjects(filter: { category: { eq: "home" } }) {
      nodes {
        projectName
        photos {
          thumb: gatsbyImageData(width: 450, height: 220, placeholder: BLURRED)
          full: gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

export default RodinneABytoveStavby;
